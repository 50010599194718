import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import $ from 'jquery';

import '@/common/kendo-ui-license.js'; //kendo licence for Vue Wrapper

//================================================
// Style
//================================================
import '@/assets/css/common.css';
import '@/assets/css/vueform-toggle.css';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';

//kendo
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper';
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import { ValidatorInstaller } from '@progress/kendo-validator-vue-wrapper';
import { Input } from '@progress/kendo-vue-inputs';
//Avatar, Card, Drawer, Menu, PanelBar, Splitter, Stepper, TabStrip
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-vue-layout';
import { TreeViewInstaller } from '@progress/kendo-treeview-vue-wrapper';
import { Window } from '@progress/kendo-window-vue-wrapper';
import { Notification, Tooltip } from '@progress/kendo-popups-vue-wrapper';
import {
    DropDownList,
    MultiSelect,
    DropdownsInstaller,
    ComboBox,
} from '@progress/kendo-dropdowns-vue-wrapper';
import { Upload, UploadInstaller } from '@progress/kendo-upload-vue-wrapper';
import { EditorInstaller } from '@progress/kendo-editor-vue-wrapper';
import Toggle from '@vueform/toggle';
import {
    DatePicker,
    DateinputsInstaller,
} from '@progress/kendo-dateinputs-vue-wrapper';
// import {
//     DropDownTree,
//     DropDownTreeInstaller,
// } from '@progress/kendo-dropdowntree-vue-wrapper';

//for export kendo grid
import JSZip from 'jszip';
window.JSZip = JSZip; //for export excel
import kendo from '@progress/kendo-ui';
kendo.pdf.defineFont({
    NotoSansKR: '/fonts/nanum.ttf',
    'NotoSansKR|Bold': '/fonts/nanum.ttf',
    'NotoSansKR|Bold|Italic': '/fonts/nanum.ttf',
    'NotoSansKR|Italic': '/fonts/nanum.ttf',
});

//================================================
// Kendo Theme
//================================================
// import '@progress/kendo-theme-default/dist/all.scss'; //default theme
import '@progress/kendo-theme-bootstrap/dist/all.scss'; //bootstrap theme
// import '@progress/kendo-theme-material/dist/all.scss'; //material theme

//================================================
// Kendo culture
//================================================
// import '@progress/kendo-ui/js/cultures/kendo.culture.ko-KR.js';

// createApp(App).use(store).use(router).use(i18n).mount('#app');
/**
 * Kendo Component는 View 파일에서 직접 import 하지 않고
 * main에서 한번만 입력한다.
 */
const app = createApp(App)
    .use(store)
    .use(router)
    .use(Input)
    .use(GridInstaller)
    .use(DropDownList)
    .use(MultiSelect)
    .use(DropdownsInstaller)
    .use(ValidatorInstaller)
    .use(DatePicker)
    .use(DateinputsInstaller)
    .use(UploadInstaller)
    .use(EditorInstaller)
    .use(ComboBox)
    .use(Splitter)
    .use(DataSourceInstaller)
    .use(TreeViewInstaller)
    .use(DatePicker)
    .use(DateinputsInstaller)
    .component('Toggle', Toggle)
    .component('k-notification', Notification) //팝업에서 사용
    .component('k-tabstrip', TabStrip)
    .component('k-tabstrip-tab', TabStripTab)
    .component('k-window', Window)
    .component('k-input', Input);

//================================================
// Notification
//================================================
import Notifications from '@kyvg/vue3-notification';
import notify from '@/modules/notification';
app.use(Notifications);
app.config.globalProperties.$notify = notify;
window.$ = window.jQuery = $;
//================================================
// Event Bus
//================================================
// const emitter = mitt();
// app.config.globalProperties.$emitter = emitter;

//================================================
// Mount
//================================================
app.mount('#app');
